import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Blog from '../components/Blog';

const TagTemplate = ({data, location, pageContext}) => {
    const {edges} = data.allMarkdownRemark;
    const tag = location && location.pathname.split('/').filter(t => t)
    return (<Layout title="CyberVision - Blog" location={location} darkBreadcrumbs pageTitle={`TAG: ${tag[tag.length - 1]}`}>
        <Blog edges={edges} location={location} pageContext={pageContext}/>
    </Layout>)
}

export const query = graphql`
    query PostByTagSlug ($tagSlug: String ) {
        allMarkdownRemark(
            filter: {frontmatter: {template: {eq: "post"}, draft: {ne: true}}, fields: {tagSlugs: {eq: $tagSlug}}}
            sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    fields {
                        slug
                        categorySlug
                        tagSlugs
                    }
                    frontmatter {
                        title
                        date
                        tags
                        category
                        description
                        socialImage
                    }
                }
            }
        }
    }
`;

export default TagTemplate;
